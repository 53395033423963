module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"once":true,"disable":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paul Siebers Personal Site","short_name":"sieber.dev","start_url":"/","background_color":"#3991AC","theme_color":"#3991AC","display":"minimal-ui","icon":"assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d845845ea1e9caa32b436942f3009258"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
